import { GetStaticProps, InferGetStaticPropsType } from 'next';
import Error from 'next/error';
import getPage from '@framework/api/operations/get-page';
import getNav from '@framework/api/operations/get-nav';
import decoratePageContent from '@utils/decorate-page-content';
import Layout from '@components/Layout';
import Page from '@components/Page';
import { REVALIDATE_VALUES } from '@constants';

// Separating this logic from the custom _error page
// so that we don't record an exception in Sentry for 404s

export const getStaticProps: GetStaticProps = async ({
  locale,
  locales,
  preview,
}) => {
  const nav = await getNav({ slug: 'main', locale, preview });
  const page = await getPage({ slug: '404', locale, preview });

  const { content, ...rest } = page;

  const decoratedContent = await decoratePageContent(content);

  return {
    props: {
      preview: preview || false,
      locale,
      locales: JSON.stringify(locales),
      page: { content: decoratedContent, ...rest },
      nav,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

const NotFound: InferGetStaticPropsType<typeof getStaticProps> = ({ page }) =>
  !page ? <Error statusCode={404} /> : <Page page={page} />;

NotFound.Layout = Layout;

export default NotFound;
